import React, {useEffect, useState} from 'react'
import {DataCard} from "./Maturity Sources/components/DataCard";
import api from "./api";
import {message, Table} from "antd";
import FileDownload from "js-file-download";

export function Reports({}) {

    const [reports, setReports] = useState([])

    const getReports = () => {
        api.get(`/register/reports/list`)
            .then(json => setReports(json.data))
    }

    useEffect(() => {
        getReports()
    }, []);

    const workWithDate = dateStr => {
        if (!dateStr) {
            return ''
        } else {
            const options = {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
            };
            return new Date(dateStr).toLocaleDateString(undefined, options) + ' ' + new Date(dateStr).toLocaleTimeString()
        }
    }

    const download = (_file) => {
        message.info('Starting exporting the data! Could take a while...')
        const config = {headers: {}}
        config['responseType'] = 'blob';

        api.post(`/reports/download`, {
            path: _file.file,
            name: _file.name
        }, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename, name);
            })
    }

    const getDuration = (startDateStr, finishDateStr) => {

        if (!finishDateStr) {
            return ''
        }

        const getRemains = (_diff, base) => {
            return _diff % base
        }

        let startDate = new Date(startDateStr)
        let finishDate = new Date(finishDateStr)

        let diff = Math.abs(finishDate - startDate) / 1000
        if (diff < 60) {
            return `${getRemains(diff, 60)} second(s).` ;
        } else if (diff < 3600) {
            return `${Math.floor(diff / 60)} minute(s) ${getRemains(diff, 60)} second(s).` ;
        } else if (diff < 86400) {
            return  `${Math.floor(diff / (60 * 60))} hour(s) ${Math.floor(getRemains(diff / 60, 60))} minute(s) ${getRemains(diff, 60)} second(s).` ;
        } else {
            return `${Math.floor(diff / (60 * 60 * 24))} day(s) ${Math.floor(getRemains(diff / (60 * 60), 24))} hour(s) ${Math.floor(getRemains(diff / 60, 60))} minute(s) ${getRemains(diff, 60)} second(s).` ;
        }
    }

    let columns = [
        {
            title: 'Register Type',
            dataIndex: 'type',
        },
        {
            title: 'User',
            dataIndex: 'user',
        }, {
            title: 'Export File',
            dataIndex: 'name',
            render: export_type=> <div>{`${export_type.toUpperCase()}`}</div>
        },
        {
            title: 'Started',
            dataIndex: 'started',
            key: 'started',
            align: 'center',
            render: (start) =>  <div>{workWithDate(start)}</div>
        },
        {
            title: 'Duration',
            dataIndex: 'finished',
            key: 'finished',
            align: 'center',
            render: (finished, item) =>  <div>{getDuration(item['started'], finished)}</div>
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Actions',
            dataIndex: 'file',
            render: (filepath, file) => <div>
                {filepath && <div className={'beautiful-link'} onClick={() => download(file)}>Download</div>}
            </div>
        }
    ];

    return <DataCard style={{margin: '70px 10px 10px 10px',  height: "calc(100vh - 80px)",
        maxHeight: "calc(100vh - 80px)",}} title={'Reports'}>
        <Table size={'small'} columns={columns} dataSource={reports} />
    </DataCard>

}
